let readMore = $('.read-more');
console.log(readMore);
if(readMore[0] !== undefined) {
    console.log('aa');
    let text = readMore[0].childNodes[1];
    let textHTML = text.innerHTML;
    text.innerHTML = "A";
    let lineHeight = text.clientHeight;
    let parentHeight = readMore[0].parentNode.parentNode.parentNode.clientHeight + 120;
    text.innerHTML = textHTML;
    for(let i=0; i<readMore.length; i++) {
        text = readMore[i];
        let lines = Math.ceil(text.clientHeight / lineHeight);
        let parent = readMore[i].parentNode.parentNode.parentNode;
        console.log(parent);
        if( lines > 5) {
            parent.style.maxHeight = parentHeight + "px";
            readMore[i].style.maxHeight = 5*lineHeight + "px";
            const button = document.createElement("p");
            button.classList.add("read-more-btn");
            button.innerHTML = "Czytaj więcej";
            readMore[i].parentNode.insertBefore(button, readMore[i].nextSibling);
            button.onclick = function() {
                if(readMore[i].style.maxHeight !== (lines)*lineHeight + "px") {
                    parent.style.maxHeight = parentHeight + (lines-5)*lineHeight + "px";
                    readMore[i].style.maxHeight = (lines)*lineHeight + "px";
                    button.innerHTML = "Zwiń";
                } else {
                    parent.style.maxHeight = parentHeight + "px";
                    readMore[i].style.maxHeight = 5*lineHeight + "px";
                    button.innerHTML = "Czytaj więcej";
                }

            }
        } else {
            parent.style.height = parent.clientHeight+ "px";
        }
    }
}
